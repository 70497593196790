import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import Hero from "../components/Hero"
import Title from "../components/Title"
import HeroImg from "../images/hero-terms.jpg"
import LinedText from "../components/LinedText"
import Section from "../components/Section"
import { Wrapper, Grid, Tile } from "../components/UI/Grid"
import Element from "../components/UI/Element"
import { List } from "../components/UI/List"

const Terms = () => {
  return (
    <>
      <Seo title="Terms & Conditions" />
      <BlurredSection>
        <Hero img={HeroImg} page="inner">
          <Wrapper>
            <TitleStyled size="d4" lg="d1">
              <LinedText title="Terms and Conditions" />
            </TitleStyled>
          </Wrapper>
        </Hero>
        <Section>
          <Wrapper>
            <Grid justifyContent="center">
              <Tile lg={10}>
                <Element as="p" mb={9}>
                  By accessing and using the Website (<Element fontWeight="bold" as="span">tajarahospitality.com</Element>),
                  owned and operated by{" "}
                  <Element fontWeight="bold" color="brand" as="span">
                    TAJARA Leisure and Hospitality Group Inc.
                  </Element>{" "}
                  (the "Company"), You agree to be bound by the terms and
                  conditions set forth below. If You do not agree to be bound by
                  this Agreement, do not understand the Agreement, or if You
                  need more time to review and consider this Agreement, please
                  leave the Website immediately. The Company only agrees to
                  provide use of the Website and Services to You if You assent
                  to this Agreement.
                </Element>
                <Element as="p" mb={6} fontWeight="bold">
                  1. DEFINITIONS
                </Element>
                <Element as="div" mb={9}>
                  The parties referred to in this Agreement shall be defined as
                  follows:
                  <List listStyle="disc">
                    <li>
                      Company, Us, We: The Company, as the creator, operator,
                      and publisher of the Website, makes the Website and
                      certain Services on it, available to users. <Element fontWeight="bold" as="span">TAJARA Leisure
                      and Hospitality Group Inc.</Element>, Company, We, Us, Our, Ours and
                      other first person pronouns will refer to the Company, as
                      well as all employees and affiliates of the Company.
                    </li>
                    <li>
                      You, the User, the Client: You, as the user of the website
                      will be referred to throughout this Agreement with
                      second-person pronouns such as You, Your, Yours, or as
                      User or Client.
                    </li>
                    <li>
                      Parties: Collectively, the parties to this Agreement (the
                      Company and You) will be referred to as Parties.
                    </li>
                  </List>
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  2. AGE RESTRICTION
                </Element>
                <Element as="p" mb={9}>
                  You must be at least Eighteen (18) years of age to use this
                  Website or any Services contained herein. By using this
                  Website, You represent and warrant that You are at least 18
                  years of age and may legally agree to this Agreement. The
                  Company assumes no responsibility or liability for any
                  misrepresentation of Your age.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  3. INTELLECTUAL PROPERTY
                </Element>
                <Element as="p" mb={9}>
                  The Website, including all content features in the Website
                  including all text, graphics,images, logos, trademarks, and
                  the link (the "Content"), and Services provided by the Company
                  are the property of the Company. You agree that that the
                  Company owns all right, title, interest in and to the Content
                  and that You will not use the Content for any unlawful or
                  infringing purpose.
                  <br />
                  <br />
                  Subject to this Agreement, the Company grants You a
                  non-exclusive, limited, non-transferable, and revocable
                  license to use the Content solely in connection with Your use
                  of the Website and Services. The Content may not be used for
                  any other purpose, and this license terminates upon Your
                  cessation of the use of the Website or Services or at the
                  termination of this Agreement.
                  <br />
                  <br />
                  You agree not to reproduce or distribute the Content in any
                  way, including electronically or via registration of any new
                  trademarks, trade names, service marks, or Uniform Resource
                  Locators (URLs), without express written permission from the
                  Company.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  4. ACCEPTABLE USE
                </Element>
                <Element mb={9}>
                  As a condition of Your use of the Website or Services, You
                  agree not to use the Website or Service for any unlawful
                  purpose or any purpose prohibited under this clause. You agree
                  not to use the Website or Services in any way that could
                  damage the Website, Services, or general business of the
                  Company.
                  <br />
                  <br />
                  You further agree not to use the Website or Services to:
                  <List listStyle="disc">
                    <li>
                      Harass, abuse, or threaten others or otherwise violate any
                      person's legal rights;
                    </li>
                    <li>
                      Violate any intellectual property rights of the Company or
                      any third party;
                    </li>
                    <li>Perpetrate any fraud;</li>
                    <li>
                      Engage in or create any unlawful gambling, sweepstakes, or
                      pyramid scheme;
                    </li>
                    <li>
                      Attempt to gain unauthorized access to the Website or
                      Service, other accounts, computer systems or networks
                      connected with the Website or Service;
                    </li>
                    <li>
                      Transmit or upload any content or material that contains
                      viruses, trojan horses, ransomware, or other harmful or
                      deleterious programs or software;
                    </li>
                    <li>
                      Publish or distribute any obscene or defamatory material;
                    </li>
                    <li>
                      Publish or distribute any material that incites violence,
                      date, or discrimination towards any group;
                    </li>
                    <li>
                      Unlawfully gather information about others, including
                      email addresses;
                    </li>
                    <li>
                      Interfere with another user's use and enjoyment of the
                      Website or Service or any similar Website or Service.
                    </li>
                  </List>
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  5. THIRD PARTY LINKS
                </Element>
                <Element as="p" mb={9}>
                  You acknowledge that We may, from time to time, include links
                  or references to other websites, other content, or other
                  materials ("Third Party Links"), none of which are controlled
                  by Us.
                  <br />
                  <br />
                  Third Party Links are provided for Your information only and
                  We do not make any representations, warranties, or guarantees
                  as to the accuracy, completeness, performance, reliability,
                  timeliness, quality, or suitability for a particular purpose
                  of these Third Party Links. We do not endorse, approve, or
                  support these Third Party Links.
                  <br />
                  <br />
                  You use the Third Party Links at Your own risk.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  6. EXCLUSION OF LIABILITY
                </Element>
                <Element mb={9}>
                  <List listStyle="disc">
                    <li>
                      The Website and Service, and its Content, are provided for
                      general information only and may change at any time
                      without prior notice.
                    </li>
                    <li>
                      You accept and acknowledge that the Website, Service,
                      Goods, or Services may contain mistakes, errors, and
                      inaccuracies.
                    </li>
                    <li>
                      Your use of the Website, Content, and information or
                      documentation that We may provide to You in connection
                      with Your use of the Goods, Services, or Products
                      including documentation, data, and information developed
                      by Us or owned by Us, and other materials which may assist
                      in Your use of Goods or Services or Website (collectively,
                      the "Materials"), is entirely at Your risk. It is Your
                      responsibility to make sure that any Goods, Services,
                      Materials, Content, or other information available through
                      the Website or Service suits Your particular purpose.
                    </li>
                    <li>
                      Neither We, nor any third parties provide any guarantees
                      or warranties regarding the accuracy, completeness,
                      performance, reliability, timeliness, quality,
                      merchantability, safety, legality, or suitability for a
                      particular purpose of the Website, Goods, or Services.
                    </li>
                    <li>
                      To the maximum extent permitted by law, We hereby
                      expressly exclude all warranties, guarantees,
                      representations, or terms (whether express or implied)
                      except for those expressly set out in these Agreements.
                    </li>
                    <li>
                      To the maximum extent permitted by law, We hereby
                      expressly exclude any liability in relation to the
                      accuracy, completeness, performance, reliability,
                      timeliness, quality, merchantability, safety, legality, or
                      suitability for a particular purpose of the Website,
                      Goods, or Services.
                    </li>
                    <li>
                      To the maximum extent permitted by law, We hereby
                      expressly exclude any liability in relation to loss of
                      data, interruption to Your business or any damages which
                      are incidental to or arise from such loss of data or
                      interruption to business.
                    </li>
                    <li>
                      To the maximum extent permitted by law, We will not be
                      liabile for any damage, loss, cost, or expense including
                      legal costs and expenses, whether direct or indirect,
                      incurred by You in connection with Your use of the
                      Website, Goods, or Services.
                    </li>
                  </List>
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  7. ASSUMPTION OF RISK
                </Element>
                <Element as="p" mb={9}>
                  The Website and Services are provided for communication
                  purposes only. You acknowledge and agree that any information
                  posted on Our Website is not intended to be legal advice,
                  medical advice, or financial advice, and no fiduciary
                  relationship has been created between You and the Company.
                  <br />
                  <br />
                  You further agree that Your purchase of any products on the
                  Website is at Your own risk. The Company does not assume
                  responsibility or liability for any advice or other
                  information given on the Website.
                  <br />
                  <br />
                  We shall not be liable for any damage to any computer,
                  equipment, software, data, or other information caused by Your
                  access or use of the Website or Service. We shall likewise not
                  be liable for any action of third parties.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  8. PRIVACY
                </Element>
                <Element as="p" mb={9}>
                  Through Your Use of the Website and Services, You may provide
                  Us with certain information. By using the Website or the
                  Services, You authorize the Company to use Your information in
                  the Philippines and any other country where We may operate.
                  <br />
                  <br />
                  We take Our privacy obligations very seriously.
                  <br />
                  <br />
                  Please refer to Our privacy policy for further information
                  about what information We collect, how We use it and store it,
                  and Your rights in relation to it.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  9. COOKIES
                </Element>
                <Element as="p" mb={9}>
                  This Website uses the following Cookies:
                  <br />
                  Advertising cookies for marketing and promotional purposes
                  <br />
                  Newsletter cookies for tracking newsletter and blog article
                  consumption
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  10. INDEMNITY
                </Element>
                <Element as="p" mb={9}>
                  You agree to defend and indemnify the Company and any of its
                  affiliated (if applicable) and hold Us harmless against and
                  legal claims and demands, including reasonable attorney's
                  fees, which may arise from or relate to Your use or misuse of
                  the Website or Services, Your breach of this Agreement, or
                  Your conduct or actions.
                  <br />
                  <br />
                  You agree that the Company shall be able to select its own
                  legal counsel and may participate in its own defense, if the
                  Company wishes.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  11. OTHER ACTION
                </Element>
                <Element mb={9}>
                  We reserve the right to take any of the following actions in
                  Our sole discretion:
                  <br />
                  <List listStyle="disc">
                    <li>
                      Determine whether or not You have breached this Agreement;
                    </li>
                    <li>
                      Record any correspondence that occurs in public sections
                      of the Website or Service;
                    </li>
                    <li>
                      Review any allegations about breaches of these Website or
                      Service, and determine in Our sole discretion whether to
                      take any action in response to those alleged breaches,
                      including removal of any Content in relation to those
                      alleged breaches;
                    </li>
                    <li>
                      Determine in our sole discretion whether to terminate Your
                      or another user's access to any particular section or
                      sections of the Website or Service.
                    </li>
                  </List>
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  12. SPAM POLICY
                </Element>
                <Element as="p" mb={9}>
                  You are strictly prohibited from using the Website or any of
                  the Company's Services for spam activities, including
                  gathering email address and personal information from others
                  or sending any mass commercial emails.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  13. REVERSE ENGINEERING & SECURITY
                </Element>
                <Element mb={9}>
                  You agree not to undertake any of the following actions:
                  <List listStyle="disc">
                    <li>
                      Reverse engineer, or attempt to reverse engineer or
                      disassemble any code or software from or on the Website or
                      Services;
                    </li>
                    <li>
                      Violate the security of the Website or Services through
                      any unauthorized access, circumvention of encryption or
                      other security tools, data mining, or interference to any
                      host, user, or network.
                    </li>
                  </List>
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  14. MODIFICATION & SERVICE INTERRUPTIONS
                </Element>
                <Element as="p" mb={9}>
                  The Company may, in Our sole discretion, vary, alter, amend,
                  change, or update the Website or Service and/or its Content at
                  any time. The Company may need to interrupt Your access to the
                  Website to implement any change or to perform maintenance or
                  emergency services on a scheduled or unscheduled basis. You
                  agree that Your access to the Website may be affected by
                  unanticipated or unscheduled downtime, for any reason, but
                  that the Company shall have no liability for any damage or
                  loss caused as a result of such downtime.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  15. VARIATION OF TERMS
                </Element>
                <Element as="p" mb={9}>
                  The Company may, from time to time and at any time without
                  notice to You, modify this Agreement. You agree that the
                  Company has the right to modify this Agreement or revise
                  anything contained herein. You further agree that all
                  modifications to this Agreement and are in full force and
                  effect immediately upon posting on the Website and that the
                  modifications or variations will replace any prior version of
                  this Agreement, unless prior versions are specifically
                  referred to or incorporated into the latest modification or
                  variation of this Agreement.
                  <br />
                  <br />
                  You agree to routinely monitor this Agreement and refer to the
                  Effective date at the bottom part of this Agreement to note
                  modifications or variations. You further agree to clear Your
                  cache when doing so to avoid accessing an older version of
                  this Agreement.
                  <br />
                  <br />
                  Your continued use of the Website after any modifications to
                  this Agreement is a manifestation of Your continued assent to
                  this Agreement.
                  <br />
                  <br />
                  In the event that You fail to monitor any modifications to or
                  variations of this Agreement, You agree that such failure
                  shall be considered an affirmative waiver of Your right to
                  review the modified Agreement.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  16. ENTIRE AGREEMENT
                </Element>
                <Element as="p" mb={9}>
                  This Agreement constitutes the entire understanding between
                  the Parties with respect to any and all use of this Website.
                  The Agreement supersedes and replaces all prior or
                  contemporaneous agreements or understandings, written or oral,
                  regarding the use of this Website.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  17. APPLICABLE LAW
                </Element>
                <Element as="p" mb={9}>
                  This Agreement and Your use of the Website and all
                  non-contractual relationships arising out of Your use shall be
                  governed and construed in accordance with the laws of the
                  Philippines.
                  <br />
                  <br />
                  In case of any dispute or litigation, the Parties agree to
                  submit to the jurisdiction of the Philippines courts.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  18. ASSIGNMENT
                </Element>
                <Element as="p" mb={9}>
                  This Agreement, or the rights granted hereunder, may not be
                  assigned, sold, leased, or otherwise transferred in whole or
                  in part by You. Should this Agreement, or the rights granted
                  hereunder, be assigned, sold, leased, or otherwise transferred
                  by the Company, the rights and liabilities of the Company will
                  bind and inure to any assignees, administrators, successors,
                  and executors.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  19. SEVERABILITY
                </Element>
                <Element as="p" mb={9}>
                  If any part or sub-part of this Agreement is held invalid or
                  unenforceable by a court of law or competent arbitrator, the
                  remaining parts and sub-parts will be enforced to the maximum
                  extent possible. In such conditions, the remainder of this
                  Agreement shall continue in full force.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  20. NO WAIVER
                </Element>
                <Element as="p" mb={9}>
                  Our failure to enforce any provision of this Agreement shall
                  not constitute a waiver of any future enforcement of that
                  provision or of any other provision.
                  <br />
                  <br />
                  No waiver shall be deemed to have been made unless expressed
                  in writing and signed by Us. Waiver of any part or sub-part of
                  this Agreement will not constitute a waiver of any other part
                  or sub-part or of the same part or sub-part on a future date.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  21. HEADINGS
                </Element>
                <Element as="p" mb={9}>
                  Headings of parts and subparts under this Agreement are for
                  convenience and organization only. Headings shall not affect
                  the meaning of any provisions of this Agreement.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  22. CONTACT US
                </Element>
                <Element as="p" mb={9}>
                  You can contact us about this Agreement using the following
                  details:
                  <br />
                  TAJARA Leisure and Hospitality Group Inc.
                  <br />
                  Makati Junction Hostel, Don Pedro Street, Barangay Poblacion,
                  Makati City 1210 Philippines
                  <br />
                  Tel no: +632 85253801
                  <br />
                  Email: mychele@tajarahospitality.com
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  23. EFFECTIVE DATE
                </Element>
                <Element as="p" mb={0}>
                  This Agreement will become effective on 1 January 2022.
                </Element>
              </Tile>
            </Grid>
          </Wrapper>
        </Section>
      </BlurredSection>
    </>
  )
}

const TitleStyled = styled(Title)`
  line-height: 1;
`

export default Terms
